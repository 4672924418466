import {Gamepad2, GraduationCap, HeartPulse, MessageSquareMore, Network, NotebookPen, Palette} from "lucide-react";

export const categoryImage = {
    "IT":Network,
    "게임":Gamepad2,
    "글쓰기":NotebookPen,
    "건강":HeartPulse,
    "교육":GraduationCap,
    "예술":Palette,
    "기타":MessageSquareMore,
};
